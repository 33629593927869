import React from "react";
import { useParams } from "react-router-dom";
import projects from "../data/projects-data";

const ProjectDetails = () => {
  const { id } = useParams();
  const { title, description, image, tech, github, article, npm } =
    projects[id - 1];

  return (
    <div className="details-container">
      <h1>{title}</h1>
      <div className="row">
        <div className="col-md-8">
          <img
            src={image}
            alt={title + " project picture"}
            style={{ width: "100%" }}
          />
        </div>
        <div className="col-md-4">
          <h5>About the project:</h5>
          <p>{description}</p>
          <h5>Techology used:</h5>
          <p>{tech}</p>
          <a href={npm} style={{ display: npm ? "initial" : "none" }}>
            NPM
          </a>
          <br />
          <a href={github} style={{ display: github ? "initial" : "none" }}>
            GitHub
          </a>
          <br />
          <a href={article} style={{ display: article ? "initial" : "none" }}>
            Medium Article
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
