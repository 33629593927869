import React from "react";
import Card from "./Card";
import projects from "../data/projects-data";

const Projects = () => {
  const projectsArray = projects.map((project, i) => (
    <Card
      key={project.title}
      index={i}
      title={project.title}
      description={project.description}
      image={project.image}
    />
  ));

  return (
    <div>
      <h1>Projects</h1>
      <div className="row">{projectsArray}</div>
    </div>
  );
};

export default Projects;
