import React from "react";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-md navbar-light bg-light">
      <div className="nav-container">
        <Link className="navbar-brand mb-0 h1" to="/">
          Charlie Huang
        </Link>
        <button
          className="navbar-toggler collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="icon-bar top-bar"></span>
          <span className="icon-bar middle-bar"></span>
          <span className="icon-bar bottom-bar"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item active">
              <Link className="nav-link" to="/">
                Home <span className="sr-only">(current)</span>
              </Link>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://github.com/huangcharlie">
                GitHub
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://linkedin.com/in/huangcharlie"
              >
                LinkedIn
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://drive.google.com/file/d/1hDyjXe0ChdXoU4LiAPj6cVr7lTwKKmqV/view?usp=sharing"
              >
                Resume
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Contact
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a
                  className="dropdown-item"
                  href="mailto: charliehuang913@gmail.com"
                >
                  Email
                </a>
                <a
                  className="dropdown-item"
                  href="tel:+1-626-233-2903"
                  onClick={(e) => {
                    if (!isMobile) {
                      e.preventDefault();
                      navigator.clipboard.writeText("626-233-2903").then(() => {
                        alert("626-233-2903 was copied to the clipboard.");
                      });
                    }
                  }}
                >
                  626-233-2903
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
