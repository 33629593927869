import React from "react";
import About from "./About";
import Projects from "./Projects";

const Body = () => {
  return (
    <div className="body-container">
      <About />
      <Projects />
    </div>
  );
};

export default Body;
