import React from "react";
import { Link } from "react-router-dom";

const Card = ({ title, description, image, index }) => {
  const id = index + 1;

  return (
    <div className="col-md-4">
      <div className="card mb-2" style={{ width: "100%" }}>
        <img
          className="card-img-top"
          src={image}
          alt={title + " project card image"}
        />
        <div className="card-body">
          <h5 className="card-title">{title}</h5>
          <p className="card-text">{description}</p>
          <Link to={`projects/${id}`} className="btn btn-primary">
            More Info
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Card;
