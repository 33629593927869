const projects = [
  {
    title: "GraphQLock",
    description:
      "A server-side NPM middleware library package that provides authentication and authorization for Express GraphQL servers.",
    image: process.env.PUBLIC_URL + "/assets/graphqlock-pic.png",
    tech: "GraphQL + Express.js + bcrypt + JWT + NoSQL",
    github: "https://github.com/oslabs-beta/graphQLock",
    article:
      "https://medium.com/gitconnected/graphqlock-secure-your-graphql-application-with-ease-e7bc3b85baaa",
    npm: "https://www.npmjs.com/package/graphqlock",
  },
  {
    title: "frollic",
    description:
      "Accessibility oriented venue locator curated for those with disabilities",
    image: process.env.PUBLIC_URL + "/assets/frollic-pic.png",
    tech: "Redux.js + Express.js + PostgresSQL + thunk",
    github: "https://github.com/IterAlt/frollic",
  },
  {
    title: "TwoCents",
    description: "Product/Application SAAS feedback tool",
    image: process.env.PUBLIC_URL + "/assets/feedback-pic.png",
    tech: "Redux.js + Express.js + PostgresSQL + SCSS",
    github: "https://github.com/Team-Eevee-48/TwoCents",
  },
  {
    title: "huangcharlie.com",
    description: "Portfolio Website",
    image: process.env.PUBLIC_URL + "/assets/portfolio-pic.png",
    tech: "React.js + Routers + SCSS + Bootstrap",
  },
];

export default projects;
