import React from "react";

const About = () => {
  return (
    <div className="row about-container">
      <div className="column about-image">
        <img
          src={process.env.PUBLIC_URL + "assets/profile-pic.jpg"}
          alt="Charlie Huang Headshot"
          style={{ width: "17rem" }}
        />
      </div>
      <div className="column about-section">
        <h1>Full Stack Web Developer</h1>
        <p>
          <b>Front:</b> React.js (Routers/Hooks) + Redux.js + HTML + CSS
        </p>
        <p>
          <b>Back:</b> Node.js + Express.js
        </p>
        <p>
          <b>Data:</b> SQL (Postgre) / NoSQL (MongoDB)
        </p>
        <p>
          <b>Techologies:</b> Authentication (JWT/Bcrypt/OAuth 2.0) + Git/GitHub
        </p>
      </div>
    </div>
  );
};

export default About;
